import { Button, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { LoadTestProps } from '@parloa/core-e2e-monitoring-api';

const ActiveLoadTest = (props: LoadTestProps & { onDelete(destinationNumber: string): void }) => {
  return (
    <div style={{ display: 'flex', columnGap: 16, lineHeight: 16, alignItems: 'center' }}>
      <Typography.Text strong>{`Destination: ${props.callerId}`}</Typography.Text>
      <Typography.Text>{`Active Calls: ${props.activeCalls}/${props.maxCalls ?? 'N/A'}`}</Typography.Text>
      {props.createdAt && (
        <Typography.Text>
          {'Duration: '}
          {Math.ceil(dayjs(props.finishedAt).diff(dayjs(props.createdAt) ?? Date.now()) / 1000)}
          {' Seconds'}
        </Typography.Text>
      )}
      {props.finishedAt && 'Finished'}

      <Button icon={<DeleteOutlined />} onClick={() => props.onDelete(String(props.callerId))} />
    </div>
  );
};

export default ActiveLoadTest;
