import * as Ant from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import React from 'react';

import { ApplicationProps } from '@parloa/core-e2e-monitoring-api';
import ModalContent from '../modal-content';

const format = 'HH:mm:ss';

interface Props {
  record: ApplicationProps;
  visible: boolean;
  onConfirm(record: Partial<ApplicationProps>): void;
  onCancel(): void;
  onDelete(id: string): void;
}

const EditApplication: React.FunctionComponent<Props> = (props) => {
  const [form] = useForm();

  React.useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({
        ...props.record,
        timeFrame: props.record.timeFrame
          ? [dayjs(props.record.timeFrame?.start, format), dayjs(props.record.timeFrame?.end, format)]
          : undefined,
      });
    }
  }, [props.visible, props.record, form]);

  const onOk = async () => {
    try {
      await form.validateFields();
      const values = {
        ...form.getFieldsValue(),
        number: (form.getFieldValue('number') as string)?.replace(/\s/g, ''),
      };

      const start = values.timeFrame?.[0];
      const end = values.timeFrame?.[1];

      props.onConfirm({
        ...values,
        timeFrame:
          start && end
            ? {
                start: dayjs(start).format(format),
                end: dayjs(end).format(format),
              }
            : null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = () => {
    props.onDelete(props.record._id.toString());
  };

  return (
    <Ant.Modal
      destroyOnClose
      open={props.visible}
      okText="Save"
      onOk={onOk}
      onCancel={props.onCancel}
      afterClose={form.resetFields}
      title="Edit Monitoring Instance"
      width="50%"
      maskClosable={false}
    >
      <ModalContent form={form} />
      <Ant.Popconfirm
        title="Are you sure you want to delete this voice app monitor?"
        onConfirm={onDelete}
        okButtonProps={{ danger: true }}
        okText="Yes, finally delete it!"
      >
        <Ant.Button style={{ width: '100%' }} size="large" type="primary" danger>
          Delete
        </Ant.Button>
      </Ant.Popconfirm>
    </Ant.Modal>
  );
};

export default EditApplication;
